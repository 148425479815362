import React, { useEffect, useState } from 'react'
import "../css/login.css";
function Login() {
    const [login, setLogin] = useState(false);
    const [employer, setEmployer] = useState(false);
    const [name, setFullName] = useState('');
    const checkEmployers = () => {
        setEmployer(true)
    }
    const Validate = () => {
        if (name != '' && name == 'leodomsolar@gmail.com') {
            setLogin(true);

        } else {
            alert("Please text my email to login");
        }
    }
    useEffect(() => {
        if (login) {
            window.location.replace('/resume');
        }
    }, [login])
    return (
        <>
            <div className="main-container">
                <div className='row w-100'>
                    <div className="col-md-5">
                        <div className="card" style={{ width: "100%" }}>
                            <img src="https://cdn-icons-png.flaticon.com/512/5850/5850276.png" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title"> Tôi là sinh viên</h5>
                                <a href="https://news.trungthanhweb.com/" className="btn btn-primary">
                                    Go Here
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card" style={{ width: "100%" }}>
                            <img src="https://cdn-icons-png.flaticon.com/512/7237/7237991.png" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">I'm the employer</h5>
                                <a href="#" onClick={(e) => checkEmployers()} className="btn btn-warning mr-2">
                                    I'm
                                </a>
                                <span className='ms-2'></span>
                                <a href="/resume" className={`btn btn-primary ms-2 ${!login ? 'disabled' : ''}`}>
                                    Go Here
                                </a>
                                {employer && (
                                    <div className='row mt-3'>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="My email address"
                                                aria-label="Recipient's username"
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                            <button style={{ left: '0px' }}
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                onClick={(e) => Validate()}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login